.container-opcion{
    visibility: hidden;
    background-color: rgb(255, 255, 255);
    color: #000;
    text-align: center;
    border-radius: 5px;
    border: 1px solid brown;
    margin-left: 5px;
    position: relative;
    margin-top: 2em;
}