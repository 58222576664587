.container-articulos{
    margin-top: 3em;
}
/* .card{
    width: 10rem;
    margin-left: 1em;
    padding: 1em 2em 2em 2em;
    border: 2px solid red;
} */
.volver-atras{
    margin-top: 1em;
}
.img-producto-perros{
    width: 60%;
}
.link-producto{
    text-decoration: none;
}
.link-nombre-producto{
   text-align: center;
   font-size: 90%;
   color: #000000;
   font-family: "Roboto";
   text-decoration: none;
}
.link-nombre-producto:hover{

   color: #2b2b2b;
}
.card-img-top:hover{
    transform: scale(1.1);
    transition: 2s;
}



/* .nombre-prod{
    font-size: 120%;
} */
.flechita{
    font-size: 150%;
    color: #000000;

}
.volver-btn{
    text-decoration: none;
    color: #000000;
    margin-top: 2em;
}

.volver-btn:hover{
    color: #000000;

}
.link-nombre-prod{
font-size: 80%;
text-decoration: none;
color: #000000;
}

@media (max-width: 992px){

    .card{
        width: 15rem;
        margin-left: 1em;
      
        border: 2px solid red;
    }
    .link-nombre-producto{
        text-align: center;
        font-size: 90%;
        color: #000000;
        font-family: "Roboto";
        text-decoration: none;
     }
}