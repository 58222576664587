.lista-articulos-blog{
    list-style: none;
}
.tiempo{
   color: rgb(202, 39, 39);
}
.h{
   color: rgb(202, 39, 39);
}
.i{
   color: rgb(202, 39, 39);
}
.a{
   color: rgb(202, 39, 39);
}

.h1-articulo-blog{
    margin-top: 1em;
    font-family: 'Kalam', cursive;
}

.img-perros-articulo{
   width: 100%;
}
.flechita{
   font-size: 150%;
   color: #000000;

}
.volver-btn{
   text-decoration: none;
   color: #000000;
}

.volver-btn:hover{
   color: #000000;

}

@media (max-width: 992px) {

    .texto-articulo{
      padding-top: 2em;
      text-align: left;
      
    }
}