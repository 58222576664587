/*---------------CONTAINER DE PRODUCTO-------------------*/
.otro-producto {
    width: 100%;
 
}

/*---------------IMAGEN DE PRODUCTO-------------------*/

.otro-producto img {

    width: 100%;
    


}

/*---------------IMAGEN DE RECTANGULO-------------------*/

/* .otro-producto div img {
    margin-top: 8em;
    z-index: -1;
    opacity: 0.7;
    

}
 */
/*---------------PARRAFO DE PRODUCTO-------------------*/
/* .otro-producto div p {
    z-index: 3;
    padding-top: 8em;
    color: black;
    margin-left: 3em;

} */