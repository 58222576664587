.articulo-vista{
    margin-top: 2em;
}

.img-articulos{
    width: 100%;
}
.flechita{
    font-size: 150%;
    color: #000000;

}

.titulo-articulo{
    text-align: center;
    font-family: 'Kalam', cursive;
    font-size: 120%;
}
.volver-btn{
    text-decoration: none;
    color: #000000;
}

.volver-btn:hover{
    color: #000000;

}

.btn-blog-a{
    width: 30%;
    margin-top: 0.5em;
}
