.container-opcion-tamaño {
    background-color: rgb(240, 217, 218);
    color: #000;
    text-align: center;
    border-radius: 5px;
    border: 1px solid brown;
  margin-left: 1em;
    position: relative;
    margin-top: 3em;
}
.container-opcion-tamaño p:nth-of-type(1) {
 
    margin-top: 1em;
    
}
.container-opcion-tamaño p:nth-of-type(2) {
 
    font-weight:500; 
    
}
.precio{
    margin-left: 0.3em
}

.img-producto-detalle {
    width: 60%;
    margin-top: 5em;

}

.titulo-producto-detalle {
    margin-top: 2em;
    margin-left: 0.2em;
    font-family: "Roboto";
}

.descripcion-producto-detalle {
    margin-top: 2em;
    font-size: 100%;
    margin-left: 0.5em;
}

.btn-mas {
    border: none;
    border-radius: 5px;
    background-color: rgb(202, 39, 39);
    color: #ffffff;
    margin-left: 1em;
}
.consulta-mensaje{
    background-color: rgb(79, 165, 79);
    color: #ffffff;
    border-radius: 10px;
    padding-top: 0.5em;
    padding-bottom: 0.2em;
    text-align: center;
    margin-bottom: 1em;
    font-size: 120%;
    margin-left: 1em;
}
.fa-whatsapp{
    font-size: 150%;
}
.btn-agregar-carrito{
    background-color: rgb(202, 39, 39);
    border: none;
    padding: 0.5em 1em 0.5em 1em;
    border-radius: 5px;
    color: #ffffff;
    margin-left: 0em;
    margin-bottom: 3em;
}
.flechita{
    font-size: 150%;
    color: #000000;

}
.volver-btn{
    text-decoration: none;
    color: #000000;
}

.volver-btn:hover{
    color: #000000;

}
.cantidad-prod{
    margin-left: 1em;
    font-size: 130%;
}

@media (max-width: 992px) {
    .descripcion-producto-detalle {
        margin-top: 2em;
        font-size: 80%;
    }
.cantidad-prod{
    margin-left: 1em;
    font-size: 130%;
}
}

