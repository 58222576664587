.swiper {
  width: 100%;
  height: 100%;
  
}
.portada-marca{
  position: relative;
  top: 0em;
  margin-bottom: 5em;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.marcas{
  margin-top: 5em;
}
.titulo-marcas{
  margin-top: 2em;
  font-family:"Quicksand";
}

@media (max-width: 992px) {
  .nombre-prod-dest{
    font-size: 70%;
  }
  .titulo-destacados{
    font-family:"Quicksand";
    margin-top: 2em;

  }
  .swiper-button-next::after,.swiper-button-prev::after{
    font-size: 50%;
  } 
  .mySwiper {
 
    padding-top: 2em;
  }
  .portada-marca{

  top: -1.5em;
  margin-bottom: 2em;

  }


}