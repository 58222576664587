.img{
width: 100%;
padding: 1em 1em 1em 1em;
}
.tabla-productos{
    margin-top: 3em;
    font-family: "Roboto";
}
.nombre-producto{
    font-size: 90%;
}
.fa-xmark{
    cursor: pointer;
}
.aviso-carrito{
    margin-top: 5em;
    margin-bottom: 20em;
    text-align: center;
}
.btn-vaciar{
    background-color: rgb(202, 39, 39);
    border: none;
    padding: 0.5em 1em 0.5em 1em;

    color: #ffffff;
    float: right;
   /*  color: #ffffff; */
}

.vaciar-carrito{
   color: #000000;
    border: none;
    
}
.carrito-footer{
    margin-top: 30em;

}

.trash{
    cursor: pointer;
}