.container-articulos{
    margin-top: 3em;
}

.volver-atras{
    margin-top: 1em;
}

.img-producto-perro{
    width: 100%;
}

/* .nombre-prod{
    font-size: 120%;
} */
.flechita{
    font-size: 150%;
    color: #000000;

}
.volver-btn{
    text-decoration: none;
    color: #000000;
}

.volver-btn:hover{
    color: #000000;

}
.link-nombre-prod{
font-size: 80%;
text-decoration: none;
color: #000000;
}