#page-wrap {
    text-align: center;

    /* Prevent sidebar from showing a scrollbar on page */
    overflow: auto;

  }
  @media (min-width: 992px) {
    .bm-burger-button {
   display: none;
    }
}
  .categorias-menu{
    color: #ffffff;
    text-decoration: none;
  }
  .categorias-menu:hover{
    color: #000;
    text-decoration: none;
  }
  .subMenu{
    color: #ffffff;

  }


  
  /* Individual item */
  .bm-item {
    display: inline-block;
  
    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;

  }
  

  
  /* Change color on hover */
/*   .bm-item:hover {
    color: white;
  } */
  
  /* The rest copied directly from react-burger-menu docs */
  
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 0.5em;
  }
  .subMenu{
    background-color: rgb(245, 202, 204);

    font-family: "Roboto";
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background-color: rgb(255, 255, 255);

  }
  .fondo {
    background-color: #373a47;

  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;

  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #ffffff;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background-color: rgb(245, 202, 204);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;

  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  

  @media (max-width: 992px) {
    .bm-burger-button {
      position: absolute;
      width: 25px;
      height: 20px;
      left: 1em;
      top: 6em;
    }

    .fa-magnifying-glass{
      margin-left: -1.5em;
    }
    .buscador{
      width: 60%;
    }
  }

 