.link-c{
    text-decoration: none;
 color: #000000;

}
.link-c:hover{
  
 color: #000000;
}
.container-articulos-categorias{
    margin-bottom: 10em;
    position: relative;
}

/* .lista-c li::before{
    content: "\2d";
    color: gray;
    font-size: 155%;
    padding-right: .2em;
    font-family: "FontAwesome";
    } */
    .lista-c{
        list-style: none;
    }
    .lista-c li{
        padding-top: 1em;
     
        }

        @media (max-width: 992px){

            .container-articulos-categorias{
                display: none;
            }
        }