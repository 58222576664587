.img-header{
   width: 2em;
    position: relative;
    left: 7em;
}
.siteInfo{
  margin-top: 1em;

}
.fa-van-shuttle{
  color: #c44343;
}
.fa-phone{
  color: #000000;
}
.container-header-banner{
    height: 3em;
    background-color: #ffffff;
}
.siteInfo ul li{
  display: inline-block;
}
.siteInfo .promo{
color: #000000;
  margin-top: .5em;
  padding-left: 3em;
  font-family: "Roboto";
  
  
}
.siteInfo .promo{

text-decoration: none;
}
.title-envios{
    position: relative;
    top: 0em;
}
.title-llamadas{
    position: relative;
    top: 0em;
}

  .topbar .row {
    align-items: center;
    min-height: 36px;
  }
  
  .topbar .site-info a {
    display: inline-flex;
    align-items: center;
    color: #A1AAA7;
    text-decoration: none;
  }
  
  .topbar .site-info a:hover {
    text-decoration: none;
  }
  
  .topbar .site-info a span {
    font-size: 16px;
    margin-right: 6px;
  }
  
  .topbar .site-info .divider {
    margin-left: 8px;
    margin-right: 8px;
    color: #BEC8B8;
  }
  
  .topbar .social-mini-button a {
    display: inline-block;
    padding: 4px 8px;
    color: #A1AAA7;
  }
  
  .topbar .social-mini-button a:hover {
    text-decoration: none;
    color: #6E807A;
  }
.site-info a span{
  color: #000000;

}

.fa-phone{
  margin-left: .5em;
}
.site-info{
  margin-top: .5em;
}
@media (max-width: 992px) {
  .container-header-banner{
    height: 4em;
  }
  .siteInfo{
    visibility: hidden;
  }
  .site-info{
margin-top: 2em;
  }
}
  
/* .top-bar {
    position: relative;
    height: 2em;
    display: flex;
    align-items: center;
    background: #ffffff;
}


.top-bar .logo img {
    max-width: 100%;
    max-height: 60px;
}

.top-bar .top-bar-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.top-bar .top-bar-icon {
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 40px;
}

.top-bar .top-bar-icon i {
    margin: 0;
    color: #ffffff;
    font-size: 16px;
}

.top-bar .top-bar-text {
    padding-left: 0px;
}

.top-bar .top-bar-text h3 {
    margin: 0 0 5px 0;
    font-size: 18px;
    font-weight: 600;
}

.top-bar .top-bar-text p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
}
 */