.footer {
    margin-top: 15em;
    width: 100%;

    min-height: 40vh;
    margin-bottom: 5em;
    font-family: "Roboto";
    background-color: rgb(31, 31, 31);
    margin-bottom: 0;
}

footer div h3 {

    margin-top: 2em;
    padding-top: 2em;

    letter-spacing: 4px;
    margin-bottom: 1em;
    color: #fff;

}

footer div p {

    margin-top: .5em;
    padding-left: 2em;
    color: #fff;
    font-size: 90%;

}

footer div {
    margin-left: 3em;
    margin-top: 2em;



}

.logo-i {
    width: 5%;
    font-size: 150%;
    margin-left: 10px;
    color: #fff;
    padding-left: 2em;

}

.logo-m {
    width: 5%;
    font-size: 150%;
    margin-left: 5px;
    color: #000;
    padding-left: 2em;
}

.logo-ig,
.logo-fb {
    font-size: 200%;
    margin-left: 2px;
    color: #fff;
    cursor: pointer;
    padding-left: 1em;
}

.logo-ig:hover,
.logo-fb:hover {

    transform: scale(1.5);
    transition: 1s;



}

@media (max-width: 992px) {
    .footer {
        text-align: left;
    
        width: 100%;
        overflow-x: hidden;
    }

    footer div p {

        padding-top: -1em;
        text-align: left;
        color: #fff;

    }
    footer div h3  {

  margin-top: 0;

    }
    .logo-i {

        margin-left: 0;

    }
}