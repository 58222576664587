.form{
   background-color: rgb(245, 245, 245);
    padding: 1em 1em 1em 4em;
 margin-left: 3em;
/*     border-left: 1px solid black; */
}

.form-control{

    width: 100%;
}
.form-group{
    margin-top: 1em;
    width: 96%;
}

.form-select{
    margin-top: 1em;
    width: 96%;
}
.btn-p{
    margin-top: 1em;
    background-color: rgb(255, 189, 9);
    border: none;
    padding: 0.5em 1em 0.5em 1em;

    color: #ffffff;
    
  
}
.btn-pagar{
    margin-top: 0;
    background-color: rgb(202, 39, 39);
    border: none;
    padding: 0.5em 1em 0.5em 1em;
 
    color: #ffffff;
    text-decoration: none;
 
}
.btn-cupon{
    margin-top: 1em;
    background-color: rgb(202, 39, 39);
    border: none;
    padding: 0.5em 1em 0.5em 1em;

    color: #ffffff;
    text-decoration: none;
 
}
.logo-mp{
width: 50%;
}
.pago-total{
    text-decoration: underline;
}
.btn-pagar:hover{
    background-color: rgb(202, 39, 39);
    color: #ffffff;
}
.btn:hover{
    background-color: rgb(202, 39, 39);
    color: #ffffff;
}
@media (max-width: 992px) {
    .form{
   
        padding: 1em 1em 1em 2em;
        margin-left: 0em;
      /*   border-left: 1px solid black; */
    }
    
}
