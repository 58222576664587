.swiper {
  width: 100%;
  height: 100%;

}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  margin-top: 5em;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.nombre-prod-dest {
  text-align: left;

}

.img-destacados {

  margin: 0 auto;
}

.producto-destacado {


  align-items: center;
  margin-bottom: 3em;
  border: 1px solid rgb(214, 214, 214);
  padding-left: 5em;
  padding-right: 5em;


  padding-bottom: 1em;
  width: 70%;

}

.titulo-destacadoss {
  font-family: 'Kalam', cursive;
  padding-top: 2em;
  margin-bottom: -1em;


}

:root {
  --swiper-theme-color: gray !important
}

.linea-destacados {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1em;

}

@media (max-width: 992px) {
  .swiper-slide {
    margin-top: 2em;
  }

  .nombre-prod-dest {

    font-size: 30%;
  }

  .producto-destacado {
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-size: 70%;
    width: 90%;
    line-height: 1em;
  }

  .titulo-destacadoss {
    font-family: 'Kalam', cursive;
    margin-top: 0em;
    margin-bottom: -3em;
font-size: 90%;

  }

  .mySwiper {

    padding-top: 2em;
  }

}