.promo{
    margin-top: 5em;
}

.swiper {
    width: 100%;
    height: 100%;

  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
 
  }
.promo-img-slider{
 width: 80%;
 } 
  @media (max-width: 992px){
    .swiper {
      width: 100%;
      height: 100%;
   
    }

  }