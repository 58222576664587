
.link-nombre-prod:hover{
    color: #000000;
    text-decoration: underline;

}


.link {
    text-decoration: none;
    color: rgb(53, 53, 53);
}

.link:hover {
    text-decoration: underline;
    color: rgb(53, 53, 53);
}

.flechita{
    font-size: 80%;
    color: #000000;

}
.volver-arrow{
    text-decoration: none;
    color: #000000;

}
.slide-left{
    position: relative;
    top: 5em;
}
.volver-btn:hover{
    color: #000000;

}



.container-producto {
    position: relative;
    margin-left: 2em;
    padding-top: 2em;

    text-align: center;
}

.container-imagen-producto {
    text-align: center;
 

}

@media (max-width: 992px){

.slide-left{
        position: relative;
        top: 0em;
    }
}