.img-logo {
    width: 30%;
    margin-top: 1em;
    margin-left: 0%;
}

.input-container input {
    height: 30%;
    border-radius: 10px;
    border: none;
    padding: 1.2em 1em 1.2em 1em;
    width: 100%;
    background-color: #ffffff;
    margin-top: 4em;
}

.button-search{
    background-color: transparent;
}
.cart {
 
    float: right;


}
.fa-bag-shopping{
    font-size: 60%;
    color: #ffffff;
    margin-top: 2em;
}
input::placeholder {

    color: #868686;
    font-family: "Roboto";
}



.header {
    background-color: rgb(240, 217, 218);
    width: 100%;

}

.cart {
    font-size: 250%;
    color: #000000;
 

    float: right;
}

.fa-bars {
    visibility: hidden;
}

.burger {
    visibility: hidden;
}
@media (min-width: 992px) {
    buscador{
        display: none;
    }
}
@media (max-width: 992px) {
    
    .menu{
        margin: 0 auto;
    }
    .input-container{
        display: none;
    }
    .search-bar{
        width: 100%;
        height: 3em;
       
        
        background-color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
       
        padding: 10px 20px;
        backdrop-filter: blur(4px) saturate(180%);
    }
    .container-search{
        margin-top: -1.5em; 
        width: 100%;
    }
    /*     .img {
        visibility: hidden;
    } */
    .img-logo {
        width: 100%;
        margin-top: -1em;
        margin-left: 2em;
    }

    .cart {
        font-size: 210%;
        float: right;
        margin-top: 2em;
    }
    .fa-bag-shopping{

        margin-top: 1.5em;
    }

    .header {
        height: 10vh;
    }

    .input-container input {
        height: 30%;
      
        border: none;
        padding: 0.8em 0.8em 0.8em 0.8em;
        width: 100%;
        background-color: #ffffff;
     /*    margin-top: -2em; */

    }   
 .input-container{
 
    height: 10%;
 }
    

    .fa-bars {
        visibility: visible;
        font-size: 250%;

    }

    .menu {
        padding-top: 2em;
    }

    .btn {
        width: 55px;
        height: 33px;
        line-height: 55px;
    margin-top: -3.5em;
        right: 0;
        left: 30%;
        position: absolute;
        background-color: #ffffff;
        text-align: center;
        color: white;
        font-size: 18px;
    
        cursor: pointer;
        border: none;
    
    }
    
}

/* .buscar:hover input {
    width: 240px;
  } */

/*   .buscar input:focus {
    width: 240px;
  } */
.btn {
    width: 55px;
    height: 33px;
    line-height: 55px;
    top: 4em;
    right: 0;
    left: 130%;
    position: absolute;
 
    text-align: center;
    color: white;
    font-size: 18px;

    cursor: pointer;
    border: none;

}

.btn:hover {

    border: none;

}

.buscar {
    position: relative;
    padding: 5px;
}

.buscar input {
    width: 150%;
    height: 40px;
    padding: 0 20px;
    font-size: 18px;
    color: #222;
    outline: none;
    border: 1px solid silver;

    transition: all 0.6s ease;
margin-top: 3em;
}


/* .container-search{
    width: 100%;
    min-height: 10px;
    max-height: 2em;
    padding: 5%;
    background-image: linear-gradient(rgba(0,8,51,0.9),rgba(0,8,51,0.9));
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

} */

.search-bar{
    width: 100%;
    height: 2.5em;
    margin-top: 2.5em;
    
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    border-radius: 2px;
    padding: 10px 20px;
    backdrop-filter: blur(4px) saturate(180%);
}


.search-bar input{
    background: transparent;
flex: 1;
border: 0;
margin-top: 0em;
outline: none;
padding: 5px 20px;
font-size: 80%;
color: #000000;
font-family: "Roboto";
}

.search-bar button img{
    width: 25px;
    
}


.search-bar button{
    border: 0;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: rgb(0, 0, 0);
    cursor: pointer;


}

.search-icon{
    color: rgb(0, 0, 0);
   
}

.notificacion-carrito{
    color: #ffffff;
margin-top: -3.5em;
position: relative;
left: 2em;
font-size: 40%;
border-bottom: none;
text-decoration: none;
font-family: "Roboto";
background-color: #303030;
width: 1.5em;
height: 1.5em;
border-radius: 15px;

}
.noti{
    padding-left: .5em;
}