.img-portada{
    background-image: url("../imagenes/portada.png");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 77vh; 
    
}

@media (max-width: 992px) {
    .img-portada{ 
        height: 20vh; 
        margin-top: -3em;
        
    }
    
}
