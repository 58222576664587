body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.disponiblidad{
  font-size: 140%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2{
  font-family: 'Kalam', cursive;
  text-align: center;
}
h1{
  font-family: 'Kalam', cursive;
  text-align: center;

}
.titulo-destacados{
  font-family: 'Kalam', cursive;

}
.titulo-servicios-index{
  font-size: 300%; margin-top:2em;
  font-family: 'Kalam', cursive;

}
.titulo-productos-index{
font-size: 300%;
margin-top: 2em;
}

@media (max-width: 992px) {
  
.titulo-productos-index{
  font-size: 200%;
  margin-top: 1em;
  }
  .titulo-servicios-index{
    font-size: 150%; margin-top:0em;
  
  }
  .promos{
    margin-top: -4em;
  }
  .logos-marcas{
    margin-top: 1em;
    padding-top: -1em;
    margin-bottom: 1em;

  }
  .promociones-seccion{
    padding-top: -3em;
  }
}