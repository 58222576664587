/*   .App {
    text-align: center;
  }



 */

 .App{
  overflow-x: hidden;

 }