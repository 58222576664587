.swiper {
  width: 100%;
  height: 100%;
  
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.titulo-marcass{
  margin-top: 2em;
  margin-bottom: -3em;
  font-family: 'Kalam', cursive;

}

@media (max-width: 992px) {

  .titulo-marcass{
font-size: 90%;
margin-top: 10em;
padding-bottom: -4em;


}
  .nombre-prod-dest{
    font-size: 70%;
  }
  .titulo-destacados{
    font-family: 'Kalam', cursive;
    margin-top: 2em;

  }
  .logos-marcas{
    padding-top: -4em;
    padding-bottom: 2em;
    margin-top: -6em;
  }

}