.servicios {
    margin-top: 5em;
    margin-bottom: 4em;
}

.seg {
    margin-top: 2em;
}

.item h3 {
    font-family: 'Questrial', sans-serif;
}

.item {
    box-shadow: 10px 10px 11px 0px rgba(242,222,222,1);
    -webkit-box-shadow: 10px 10px 11px 0px rgba(242,222,222,1);
    -moz-box-shadow: 10px 10px 11px 0px rgba(242,222,222,1);
    padding: 1em;
    background-color: rgb(231, 122, 127);
    margin-top: 0.5em;
  
text-align: center;
color: #ffffff;
}

.lista li::before{
content: "\f061";
font-family: "FontAwesome";
}
.lista{
    list-style: none;
    margin-top: 0;
    margin-left: -2em;
}
.titulo-servicios{
    margin-top: 1em;
    font-family: 'Kalam', cursive;
}

@media (max-width: 992px) {

    .item {
   margin-top: 1em;
    }
    .titulo-servicios{
        margin-top: 1em;
        margin-bottom: 1em;
    }

}