
.item-p{
  width: 100%;
 
}


.imgs{
  width: 100%;
}

 @media (max-width: 992px) {
  .s{
    margin-top: 0em;
  }
}
 