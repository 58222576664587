.navbar li {
    padding-right: 3em;
   /*  padding-left: -1em; */

    font-family: 'Questrial', sans-serif;

}

/*  .navbar .container{
text-align: center;
} 
 */
 .dropdown-menu li{
padding-left: 1em;
 }
.Link {
    text-decoration: none;
    color: #000000;
}

.dropdown-menu {
    width: 200%;

}

.navbar li a {
    text-align: center;

}
.navbar li{
    padding-left: -3em;
}
.navbar {
    height: 3em;
    
  margin-left: -2em;
    display: none;
}
@media (max-width: 992px) {
    .navbar {
       visibility: hidden;
    }
 
}