.blog-img-info {
    width: 110%;
    font-family: "Roboto";
   
}
.texto-blog{
    margin-left: 3.5em;
    font-family: "Roboto";
    font-size: 120%;
    
}

.titulo-blog {
    font-size: 250%;
    margin-top: 2em;
    font-family: 'Kalam', cursive;
    margin-left: 1.5em;
}

.btn-blog{
    margin-top: 2em;
   margin-left: 4em;
}
@media (max-width: 992px) {
    .blog-img-info {
        display: block;
        margin-top: 4em;
    }
    .btn-blog{
       width: 50%;
       margin-top: 0;
       margin-left: 0em;
    }
    .texto-blog{
        margin-left: 0em;
        font-family: "Roboto";
        font-size: 80%;
        
    }
    .titulo-blog {
        margin-top: 2.5em;
    font-size: 180%;
        margin-left: 0em;
    }
}