.banner{
    color: #ffffff;
    text-align: center;
    font-size: 80%;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 5px;
    padding-top: 2em;
    width: 100%;

 /*    background-color: #FAB19C; */
 background-color: rgb(31, 31, 31)
}
.banner p{
    font-size: 80%;
   
}
.cd{
    margin-right: 0.5em;
}
@media (max-width: 992px) {
    .banner{
        font-size: 80%;
        width: 100%;
    }
 
    .cd{
        margin-right: 0.5em;
    }
}