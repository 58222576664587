.swiper {
  width: 100%;
  height: 100%;

}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  margin-top: 5em;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.nombre-prod-dest {
  text-align: left;

}

.img-promos {

  margin: 0 auto;
padding-top: 1em;
}

.rectangulo {
  border: 1px solid rgb(214, 214, 214);
  margin-left: 4em;
/* height: 45vh; */
height: 140%;
/* width: 40vh; */
width: 80%;
}

.producto-promo {


  align-items: center;
  margin-bottom: 3em;

  padding-left: 2em;
  padding-right: 2em;

  margin: 0 auto;
  padding-bottom: 1em;
  width: 70%;

}
.cajaTitulo{
  padding: .5em;
}
.nombre-prod-promo {
  text-align: center;


}

:root {
  --swiper-theme-color: gray !important
}

.linea-destacados {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1em;

}

@media (max-width: 992px) {
.q{
  padding-top: -3em;
}
.cajaTitulo{
  margin-top: -1em;
  margin-left: -.5em;
  margin-right: -.5em;
  font-size: 102%;
  height: 100%;
}
  .img-promos {
    width: 40%;
    margin-left: -2em;
    padding: 1.3em;
    position: relative;
    top: 2.5em;
  }

  .swiper-slide {
    margin-top: 2em;
  }

  .rectangulo {
    /* medida de 25vh */
height: 150%;
    width: 100%;
    border: 1px solid rgb(214, 214, 214);
    margin-left: .5em;
    padding-left: .2em;
    padding-right: .2em;
    margin-bottom: 2em;
    margin-top: -2em;
    line-height: .8em;
  }

  .nombre-prod-promo {
/* margin-top: -2em; */
    font-size: 55%;

    
  }

  .producto-promo {
    padding-left: 1em;
    padding-right: 1em;
    font-size: 70%;
    width: 150%;
    line-height: .5em;
    margin-top: -3.5em;

  }

  .titulo-promociones {
    margin-top: -8em;
    font-size: 90%;
  }

  .mySwiper {

    padding-top: 2em;
  }

  .p {
    padding-top: -3em;
  }
}